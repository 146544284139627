import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 'auto',
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

const lineItems = [
  "First and foremost, Brodie is king. He asked me to include that in here!!",
  "You are stuck with me forever and always.",
  "The dining table is not a storage area!!!!",
  "You will always get final say in which dishes we order from restaurants.",
  "Events prior to noon will not require your attendance.",
  "Cilantro must always be washed before being used in an aguachile!",
  "TV Shows will need final approval by you.",
  "You will always have priority in finishing the last apple chip!",
  "You will not have to drive or put gas as long as I'm around",
  "And finally, Brodie reserves the right to not wear any outfit he does not like!"
];

interface CheckBoxListProps {
  setFinishedTerms: (finished: boolean) => void
}

export default function CheckboxList(props: CheckBoxListProps) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState<Number[]>([]);

  const handleToggle = (value: number) => () => {
    // checking to see if index is in array of checked
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      // does not exist in checked array
      newChecked.push(value);
    } else {
      // delete at current index one element
      newChecked.splice(currentIndex, 1);
    }
    
    props.setFinishedTerms(newChecked.length === lineItems.length);
    setChecked(newChecked);
  };

  return (
    <List className={classes.root}>
      {lineItems.map((value, index) => {
        const labelId = `checkbox-list-label-${index}`;

        return (
          <ListItem key={index} role={undefined} dense button onClick={handleToggle(index)}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={checked.indexOf(index) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemIcon>
            <ListItemText id={labelId} primary={value} />
          </ListItem>
        );
      })}
    </List>
  );
}