import React from 'react';
import './App.css';
import { Video } from './Video';
import GabyWelcome from './Intro';

function App() {
  const [acceptedTerms, setAcceptedTerms] = React.useState(false);

  const handleClick = (finished: boolean) => {
    setAcceptedTerms(finished);
  }

  return (
    <div className="App">
      {
        !acceptedTerms 
        ? 
        <GabyWelcome handleFinish={handleClick} />
        :
        <Video />
      }
    </div>
  );
}

export default App;
