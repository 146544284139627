import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import List from './List';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 'auto',
      width: '90%',
    },
    bodyText: {
      textAlign: 'left',
    },
    title: {
      textAlign: 'center'
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
);

interface GabyWelcomeProps {
  handleFinish : (finished: boolean) => void;
}

export default function GabyWelcome(props: GabyWelcomeProps) {
  const classes = useStyles();
  const [finishedTerms, setFinishedTerms] = React.useState(false);

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h1" gutterBottom>Merry Christmas Gaby!</Typography>
      </div>
      <div className={classes.bodyText}>
        <Typography style={{marginBottom: '20px'}} variant="body1" gutterBottom>
          I thought so hard about what I could make to show you how much I care for you, so I thought
          long and hard about what I could make! I realized that I don't have many crafting skills LOL.
          So I decided to create you this little website, that contains a secret prize. But in order to see
          that prize, you must first accept the following legally binding Terms and Conditions. Please don't
          try and find a bug while using the site! haha.
        </Typography>
        <List setFinishedTerms={setFinishedTerms} />
        <Typography style={{marginTop: '20px'}} variant="body1" gutterBottom>
          I love you so much Gaby!! I miss seeing you every day, I just can't get enough of you.
          You are my queen, my life, my everything! Merry Christmas! Here's to many more together!
        </Typography>
      </div>
      
      <Button
        variant="contained"
        color="primary"
        onClick={() => {props.handleFinish(true)}}
        disabled={!finishedTerms}
        style={{marginBottom: '10px'}}
      >
          Finish
      </Button>
    </div>
    
  );
}