import React from 'react';

export const Video = () => {
  return (
    <React.Fragment>
      <iframe style={{
        position: 'fixed',
        top: '0px',
        bottom: '0px',
        right: '0px',
        width: '100%',
        border: 'none',
        margin: 0,
        padding: 0,
        overflow: 'hidden',
        zIndex: 999999,
        height: "100%"
      }}
      title="gaby-xmas-video" src="https://player.vimeo.com/video/494257627" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
    </React.Fragment>
  )
}